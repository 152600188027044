window.angular.module('MyHippoProducer.Components').directive('selection', function () {
    return {
        restrict: 'E',
        scope: {
            for: '@',
            label: '@',
            name: '@',
            type: '@',
            value: '@',
            disabled: '<',
            disabledText: '@',
            ngModel: '=',
        },
        transclude: true,
        templateUrl: 'components/selection/selection.html',
    };
});
